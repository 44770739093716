import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    fetchSimilarProducts,
    searchByImage,
    addItemToCollection,
    getUserCollections,
    createNewCollection,
    fetchProductsByTitle
} from '../services/api';
import { LanguageCountryContext } from '../context/LanguageCountryContext';
import { FaCamera, FaRegBookmark } from 'react-icons/fa';
import Modal from 'react-modal';
import GoogleLogin from '../components/GoogleLogin';
import { useAuth } from '../context/AuthContext';

function Home() {
    const { t } = useTranslation();
    const [productUrl, setProductUrl] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    // The "User Product" that was extracted or identified
    const [userProduct, setUserProduct] = useState(null);

    // SERP results: raw array (original order) & displayed array (after sorting)
    const [rawResults, setRawResults] = useState([]);
    const [results, setResults] = useState([]);

    // Loading and messaging states
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noResultsMessage, setNoResultsMessage] = useState('');

    // Collection states & modals
    const [collections, setCollections] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState('');

    // Sort preference
    const [sortOption, setSortOption] = useState('relevance');

    // Grab language, country, and currency from context
    const { language, country, currency } = useContext(LanguageCountryContext);
    const { login } = useAuth();

    // Render Google sign-in button when sign-in modal opens
    useEffect(() => {
        if (signInModalIsOpen) {
            setTimeout(() => {
                window.google?.accounts.id.renderButton(
                    document.getElementById('googleSignInModalButton'),
                    { theme: 'outline', size: 'large' }
                );
            }, 100);
        }
    }, [signInModalIsOpen]);

    const parseUrlFromText = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/i; // i: case-insensitive
        const match = text.match(urlRegex);
        if (match) {
            return match[0];
        }
        return null;
    };

    // Helper to ensure any fragment/hash is stripped off a URL
    const sanitizeUrl = (url) => {
        try {
            const parsedUrl = new URL(url);
            parsedUrl.hash = ''; // Remove #fragment
            return parsedUrl.toString();
        } catch (error) {
            console.error('Invalid URL:', url, error);
            return url; // fallback if parsing fails
        }
    };

    // Main search handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setNoResultsMessage('');

        // Clear old data before a new request
        setResults([]);
        setRawResults([]);
        setUserProduct(null);

        try {
            let data = null;

            // 1) Eğer kullanıcı resim yüklemişse => "search-by-photo"
            if (imageFile) {
                data = await searchByImage(imageFile, country, language);
                // Formu temizle
                setImageFile(null);
                setProductUrl('');
                setImagePreview(null);
            }
            // 2) Kullanıcı resim yüklememişse => URL veya başlık ayrımı yap
            else {
                // Metinden URL çekmeye çalış
                const foundUrl = parseUrlFromText(productUrl.trim());

                if (foundUrl) {
                    // URL bulduysak, backend’e "search-by-url" endpointinden devam
                    // sanitizeUrl ile temizleyip yollayabilirsin
                    const sanitizedUrl = sanitizeUrl(foundUrl);
                    data = await fetchSimilarProducts(sanitizedUrl, country, language);

                    // Arama tamamlanınca inputları temizleyebiliriz
                    setProductUrl('');
                    setImageFile(null);
                    setImagePreview(null);
                } else {
                    // Hiç URL yoksa => yeni yazacağımız "/search-by-title" endpointine
                    const titleQuery = productUrl.trim();
                    if (!titleQuery) {
                        setErrorMessage(t('invalidUrl'));
                        return;
                    }
                    data = await fetchProductsByTitle(titleQuery, country, language);

                    // Input temizliği
                    setProductUrl('');
                }
            }

            // Buraya geldiğimizde "data" içinde backend’in döndürdüğü
            // user_product ve visual_matches verisi olmalı
            setUserProduct(data.user_product || null);

            let fetchedResults = data.visual_matches || [];
            if (fetchedResults.length === 0) {
                setErrorMessage(t('noResults'));
            }

            // Currency filtrelemesi
            fetchedResults = fetchedResults.filter(
                (product) => product.price?.currency === currency
            );

            setRawResults(fetchedResults);
            setResults(fetchedResults);
            setSortOption('relevance'); // Sort seçeneğini resetle
        } catch (error) {
            console.error('Error fetching similar products:', error);
            // This means a real exception (500, network error, etc.)
            setErrorMessage(t('noResults'));
        } finally {
            setIsLoading(false);
        }
    };

    // Handle file selection
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // Reset the input so you can pick the same file on subsequent attempts
        e.target.value = null;

        setImageFile(file);
        setProductUrl('');
        setResults([]);
        setRawResults([]);
        setUserProduct(null);
        setErrorMessage('');
        setNoResultsMessage('');

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        }
    };

    // Handle manual URL input
    const handleUrlChange = (e) => {
        setProductUrl(e.target.value);
        setImageFile(null);
        setImagePreview(null);
        setErrorMessage('');
        setNoResultsMessage('');
    };

    // Utility: discount %
    const calculateDiscount = (userPrice, similarPrice) => {
        const discount = ((userPrice - similarPrice) / userPrice) * 100;
        return discount.toFixed(2);
    };

    // Check if user and result are comparable by price
    const arePricesComparable = (userProd, product) => {
        return (
            userProd?.price?.extracted_value != null &&
            product?.price?.extracted_value != null &&
            userProd.price.currency === product.price.currency
        );
    };

    // Collections / modals logic
    const handleAddToCollectionClick = async (product) => {
        setSelectedProduct(product);
        try {
            const userCollections = await getUserCollections();
            setCollections(userCollections);
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error fetching collections:', error);
            if (error.response && error.response.status === 401) {
                // user not authenticated
                setSignInModalIsOpen(true);
            } else {
                setErrorMessage('Error fetching collections. Please try again later.');
            }
        }
    };

    const handleCollectionSelect = async (collectionId) => {
        if (!selectedProduct) return;
        try {
            await addItemToCollection(collectionId, selectedProduct);
            setModalIsOpen(false);
            setSelectedProduct(null);
        } catch (error) {
            console.error('Error adding item to collection:', error);
            setErrorMessage('Could not add the item to the collection. Please try again.');
        }
    };

    const handleCreateNewCollection = async () => {
        try {
            const newCollection = await createNewCollection(newCollectionName);
            setCollections([...collections, newCollection]);
            setNewCollectionName('');
        } catch (error) {
            console.error('Error creating new collection:', error);
            setErrorMessage('Could not create collection. Please try again.');
        }
    };

    // Sorting logic
    const handleSortChange = (e) => {
        const chosenSort = e.target.value;
        setSortOption(chosenSort);

        if (chosenSort === 'price_asc') {
            // Sort by ascending extracted_value; treat missing prices as Infinity
            const sortedByPrice = [...rawResults].sort((a, b) => {
                const priceA = a.price?.extracted_value ?? Infinity;
                const priceB = b.price?.extracted_value ?? Infinity;
                return priceA - priceB;
            });
            setResults(sortedByPrice);
        } else {
            // revert to original SERP order
            setResults(rawResults);
        }
    };

    return (
        <div className="container">
            {/* Title */}
            <div className="text-center my-5">
                <h1 className="display-4 main-brand-title">{t('appTitle')}</h1>
                <p className="lead">{t('discover')}</p>
            </div>

            {/* Search Form */}
            <form onSubmit={handleSubmit} className="mb-5 search-form">
                <div className="input-group">
                    <input
                        type="text"
                        value={productUrl}
                        onChange={handleUrlChange}
                        placeholder={t('enterProductUrl')}
                        className="form-control"
                    />
                    <label className="btn btn-secondary" htmlFor="image-upload">
                        <FaCamera />
                    </label>
                    <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isLoading}
                        style={{ opacity: isLoading ? 0.5 : 1 }}
                    >
                        {t('search')}
                    </button>
                </div>
            </form>

            {/* Loading Indicator */}
            {isLoading && (
                <div className="text-center mb-4">
                    <div className="spinner"></div>
                    <p>{t('loading')}</p>
                </div>
            )}

            {/* Error Message (for real exceptions) */}
            {errorMessage && !isLoading && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}

            {/* No-Results Message (for empty arrays) */}
            {noResultsMessage && !isLoading && (
                <div className="alert alert-info" role="alert">
                    {noResultsMessage}
                </div>
            )}

            {/* Image Preview if uploading a file */}
            {imagePreview && (
                <div className="user-product mb-4">
                    <div className="list-group-item clickable-product">
                        <img
                            src={imagePreview}
                            alt="Selected Preview"
                            className="img-thumbnail me-3"
                        />
                        <div className="product-details">
                            <h5>{t('selectedImagePreview')}</h5>
                            <p className="product-source">{t('source')}: {t('localFile')}</p>
                        </div>
                    </div>
                    <hr />
                </div>
            )}

            {/* Display the user's product if present */}
            {userProduct && userProduct.thumbnail && (
                <div className="user-product mb-4">
                    <a
                        href={userProduct.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="list-group-item clickable-product"
                    >
                        <img
                            src={userProduct.thumbnail}
                            alt={userProduct.title}
                            className="img-thumbnail me-3 product-thumbnail"
                        />
                        <div className="product-details">
                            <h5>{userProduct.title}</h5>
                            <p className="product-source">{t('source')}: {userProduct.source}</p>
                            {userProduct.price && (
                                <p className="product-price">
                                    {t('price')}: {userProduct.price.currency}{' '}
                                    {userProduct.price.extracted_value}
                                </p>
                            )}
                        </div>
                        <div>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault(); // prevents the anchor from navigating
                                    handleAddToCollectionClick(userProduct);
                                }}
                                className="btn btn-link btn-collection-icon"
                                style={{
                                    position: 'absolute',
                                    top: '50%',           // position at the vertical center
                                    right: '10px',        // adjust horizontal placement as needed
                                    transform: 'translateY(-50%)'
                                }}
                            >
                                <FaRegBookmark />
                            </button>
                        </div>
                    </a>
                    <hr />
                </div>
            )}

            {/* Sort dropdown (only if we actually have results) */}
            {results && results.length > 0 && (
                <div className="mb-3 sort-dropdown-container">
                    <label htmlFor="sortSelect" className="me-2 sort-label">
                        {t('sort')}:
                    </label>
                    <select
                        id="sortSelect"
                        value={sortOption}
                        onChange={handleSortChange}
                        className="sort-dropdown"
                    >
                        <option value="relevance">{t('sortByRelevance')}</option>
                        <option value="price_asc">{t('sortByPriceAsc')}</option>
                    </select>
                </div>
            )}

            {/* Results List */}
            {results && (
                <div className="result-container">
                    <ul className="list-group">
                        {results.map((product, index) => {
                            // Check if we can compare prices
                            const isComparable = arePricesComparable(userProduct, product);
                            const isCheaper =
                                isComparable &&
                                product.price.extracted_value < userProduct.price.extracted_value;
                            const discountRate = isCheaper
                                ? calculateDiscount(
                                    userProduct.price.extracted_value,
                                    product.price.extracted_value
                                )
                                : null;

                            return (
                                <li
                                    key={index}
                                    className="list-group-item clickable-product"
                                >
                                    <a
                                        href={product.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="product-link"
                                    >
                                        <img
                                            src={product.thumbnail}
                                            alt={product.title}
                                            className="img-thumbnail me-3"
                                        />
                                        <div className="product-details">
                                            <h5>{product.title}</h5>
                                            <p className="product-source">
                                                {t('source')}: {product.source}
                                            </p>
                                            {product.price && (
                                                <p className="product-price">
                                                    {t('price')}: {product.price.currency}{' '}
                                                    {product.price.extracted_value}
                                                </p>
                                            )}
                                            {isCheaper && (
                                                <p className="discount-rate text-success">
                                                    {t('discount')}: %{discountRate}!
                                                </p>
                                            )}
                                        </div>
                                    </a>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddToCollectionClick(product);
                                        }}
                                        className="btn btn-link btn-collection-icon"
                                    >
                                        <FaRegBookmark />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            {/* Collection Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="collection-modal"
                overlayClassName="collection-overlay"
            >
                <h2>{t('selectCollection')}</h2>

                {collections.length === 0 ? (
                    <p className="no-collections-modal-message">
                        {t('noCollectionsCreateOneBelow')}
                    </p>
                ) : (
                    <ul className="collection-list">
                        {collections.map((collection) => (
                            <li key={collection.id} className="collection-item">
                                <button
                                    onClick={() => handleCollectionSelect(collection.id)}
                                    className="collection-button"
                                >
                                    {collection.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}

                <div className="new-collection">
                    <input
                        type="text"
                        value={newCollectionName}
                        onChange={(e) => setNewCollectionName(e.target.value)}
                        placeholder={t('newCollectionPlaceholder')}
                        className="collection-input"
                    />
                    <button
                        onClick={handleCreateNewCollection}
                        className="create-collection-button"
                    >
                        {t('createCollection')}
                    </button>
                </div>
                <button
                    onClick={() => setModalIsOpen(false)}
                    className="close-modal-button"
                >
                    {t('close')}
                </button>
            </Modal>

            {/* Sign-in Required Modal */}
            <Modal
                isOpen={signInModalIsOpen}
                onRequestClose={() => setSignInModalIsOpen(false)}
                className="sign-in-modal"
                overlayClassName="collection-overlay"
            >
                <h2>{t('signInRequired')}</h2>
                <p>{t('signInMessage')}</p>
                <div
                    id="googleSignInModalButton"
                    className="center-content"
                    style={{ margin: '20px 0' }}
                >
                    <GoogleLogin
                        onLoginSuccess={() => {
                            login(); // Mark the user as logged in
                            setSignInModalIsOpen(false); // Close modal
                        }}
                    />
                </div>
                <button
                    onClick={() => setSignInModalIsOpen(false)}
                    className="modal-button close-modal-button"
                >
                    {t('close')}
                </button>
            </Modal>
        </div>
    );
}

export default Home;
