import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
    .use(HttpApi) // Çeviri dosyalarını yüklemek için
    .use(LanguageDetector) // Tarayıcı dilini algılamak için
    .use(initReactI18next) // React entegrasyonu
    .init({
        supportedLngs: ["en", "tr", "de"], // Desteklenen diller
        fallbackLng: "en", // Varsayılan dil
        debug: false, // Geliştirme sırasında true yapabilirsin
        detection: {
            order: ["localStorage", "navigator"], // Tarayıcı dili veya localStorage'dan al
            caches: ["localStorage"], // Seçilen dili sakla
        },
        backend: {
            loadPath: "/locales/{{lng}}.json", // Çeviri dosyalarının yolu
        },
    });

export default i18n;
