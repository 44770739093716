import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="custom-footer text-white text-center py-3 mt-5">
            <div className="container">
                <p className="mb-0">{t('allRightsReserved')}</p>
            </div>
        </footer>
    );
}

export default Footer;
